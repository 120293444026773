import React, { useState, useCallback } from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    backgroundColor: ({ theme }) => theme.colorPrimary,
    borderRadius: '9999px',
    display: 'flex',
  },
  textField: {
    fontWeight: 800,
    color: '#fff',
    border: ({ theme }) => `3px solid ${theme.colorPrimary}`,
    paddingLeft: '1.2em',
    borderRadius: '9999px',
    backgroundColor: 'transparent',
    '&::placeholder': {
      color: '#fff',
    },
  },
  button: {
    fontWeight: 800,
    color: ({ theme }) => theme.colorPrimary,
    backgroundColor: '#fff',
    textTransform: 'uppercase',
    border: ({ theme }) => `2px solid ${theme.colorPrimary}`,
    padding: '8px 14px',
    borderRadius: '9999px',
    '&:hover': {
      color: '#fff',
      backgroundColor: ({ theme }) => theme.colorNeutral,
      cursor: 'pointer',
    },
  },
});

function InputGroup({ label, placeholder, onSubmit }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [text, setText] = useState('');

  const callback = useCallback(() => {
    onSubmit(text);
  }, [onSubmit, text]);

  const handleUpdate = (event) => {
    setText(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      callback();
    }
  };

  return (
    <div className={classes.container}>
      <input
        type="text"
        placeholder={placeholder}
        className={classes.textField}
        onChange={handleUpdate}
        onKeyDown={handleKeyDown}
        value={text}
      />
      <button type="button" className={classes.button} onClick={callback}>
        {label}
      </button>
    </div>
  );
}

export default InputGroup;
