import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import AvatarPlaceholder from 'assets/AvatarPlaceholder.svg';

const useStyles = createUseStyles({
  container: {
    width: '100px',
    height: '100px',
    overflow: 'hidden',
    border: ({ color }) => `4px solid ${color}`,
    borderRadius: '100%',
  },
  image: {
    width: '100%',
  },
});

function Avatar({ photoURL, color }) {
  const theme = useTheme();
  const classes = useStyles({ theme, color });

  const token = localStorage.getItem('fbAccessToken');
  const image = photoURL && token ? `${photoURL}?access_token=${token}&width=100` : AvatarPlaceholder;

  return (
    <div className={classes.container}>
      <img src={image} alt="avatar" className={classes.image} />
    </div>
  );
}

export default Avatar;
