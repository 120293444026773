import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import Panel from 'components/common/Panel';

import Logo from 'assets/logo.jpg';

const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    borderRadius: '100%',
    width: '64px',
    height: '64px',
    boxShadow: ({ theme }) => theme.shadowPrimary,
    marginTop: '30px',
  },
  title: {
    fontWeight: 800,
    fontSize: '2em',
    color: '#fff',
    textAlign: 'center',
  },
  link: {
    color: ({ theme }) => theme.colorPrimary,
    textDecoration: 'none',
    '&:hover': {
      color: ({ theme }) => theme.colorNeutral,
    },
  },
});

function DataDeletion() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.wrapper}>
      <img src={Logo} alt="Peasant" className={classes.logo} />
      <h1 className={classes.title}>
        Data Deletion
      </h1>
      <Panel maxWidth="400px" style={{ alignItems: 'flex-start' }}>
        <h2 id="what-personal-data">What personal data I collect</h2>
        <p><strong>I do not</strong> collect, store, and/or process any personal data, <strong>with the exception of <a href="https://policies.google.com/privacy" className={classes.link} rel="noreferrer" target="_blank">Firebase authentication</a> to sign you in with <a href="https://www.facebook.com/policy.php" className={classes.link} rel="noreferrer" target="_blank">Facebook</a></strong>.</p>
        <p>Only your username and profile picture (not even your email address) are transmitted to my server.</p>

        <h2 id="how-to-request-the-deletion-of-your-data">How to request the deletion of your data</h2>
        <p>If you have signed in with Facebook, some data (including personally identifiable information) might be retained on Firebase.</p>
        <p>In case you want me to erase any personal data I hold about you, you can request it via an email to <a href="mailto:info@icyhippo.com" className={classes.link}>info@icyhippo.com</a>.</p>

        <h2 id="privacy-policy">Privacy Policy</h2>
        <p>If you want to learn more about how I handle your personal data, refer to my <a href="https://peasant.icyhippo.com/privacy-policy" className={classes.link}>Privacy Policy</a>.</p>
      </Panel>
    </div>
  );
}

export default DataDeletion;
