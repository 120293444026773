import React from 'react';
import { useStoreState } from 'easy-peasy';
import { createUseStyles, useTheme } from 'react-jss';

import Card from './Card';

const useStyles = createUseStyles({
  topCards: {
    gridArea: 'main',
    display: 'flex',
  },
});

function TopCards() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const topCards = useStoreState((state) => state.game.topCards);

  return (
    <div className={classes.topCards}>
      {topCards.map(({ suit, rank }, index) => (
        <Card
          key={`top_card_${suit}_${rank}`}
          suit={suit}
          rank={rank}
          style={{ marginLeft: index === 0 ? 0 : '-20px' }}
          isDiscarded={true}
        />
      ))}
    </div>
  );
}

export default TopCards;
