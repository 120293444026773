import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  button: {
    fontWeight: 400,
    fontSize: '0.8em',
    color: '#fff',
    backgroundColor: '#485cf7',
    cursor: 'pointer',
    padding: '0 2em',
    borderRadius: '9999px',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor: '#586af8',
      transition: 'background-color 200ms',
    },
  },
  bold: {
    fontWeight: 800,
  },
});

function FacebookButton({ onClick, style }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.button} onClick={onClick} style={{ ...style }}>
      <p>
        Sign in with <span className={classes.bold}>Facebook</span>
      </p>
    </div>
  );
}

export default FacebookButton;
