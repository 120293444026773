import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { AnimateSharedLayout } from 'framer-motion';
import { useSocket } from 'use-socketio';

import ControlButton from 'components/common/ControlButton';
import Sounds from 'common/Sounds';

import Card from './Card';
import Player from './Player';
import TopCards from './TopCards';
import DiscardPile from './DiscardPile';

import { ReactComponent as TradeIcon } from 'assets/icons/trade.svg';
import { ReactComponent as PassIcon } from 'assets/icons/pass.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '640px',
  },
  gameStatus: {
    fontWeight: 800,
    fontSize: '1em',
    color: '#fff',
    backgroundColor: ({ theme }) => theme.colorNeutral,
    borderRadius: '9999px',
    padding: '14px 42px',
    maxWidth: '90%',
  },
  gameArea: {
    width: 'calc(100% - 20px)',
    backgroundColor: ({ theme }) => theme.colorSecondary,
    borderRadius: '60px',
    padding: '10px',
    display: 'grid',
    gridTemplate: `
      'player2 pile player3'
      'main main main'
      'player1 self player4'
    `,
    gridTemplateRows: '1fr minmax(80px, auto) 1fr',
    justifyItems: 'center',
    alignItems: 'center',
    gap: '40px',
    boxShadow: ({ theme }) => theme.shadowPrimary,
    zIndex: 1,
  },
  board: {
    width: 'calc(90% - 40px)',
    marginTop: '-60px',
    padding: '80px 20px 20px 20px',
    backgroundColor: ({ theme }) => theme.colorNeutral,
    borderRadius: '0 0 36px 36px',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  },
  hand: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '10px',
    rowGap: '10px',
    height: '170px',
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
});

function GameView() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const {
    hand,
    players,
    playerId,
    playerTurn,
    hasTraded,
    topCards,
    gamePhase,
    hasTheLead,
  } = useStoreState((state) => state.game);

  const {
    playCards,
    setHand,
    doneTrading,
    addMove,
    setRemainingCards,
    setPlayerTurn,
    setGamePhase,
    setHasTheLead,
    setPlayers,
    resetGame,
    startGame,
  } = useStoreActions((actions) => actions.game);

  const isTurn = () => playerTurn === playerId;
  const isCitizen = () => player.role === 'citizen';

  const { socket } = useSocket();

  useSocket('TRADE_COMPLETE', (payload) => {
    if (!('hand' in payload)) {
      return;
    }

    doneTrading();
    setHand(payload.hand);
  });

  useSocket('GAME_PHASE', (payload) => {
    console.log('Updating game phase', payload);
    setGamePhase(payload);
  });

  useSocket('START_GAME', (payload) => {
    console.log('Restarting the game', payload);

    resetGame();

    setPlayerTurn(payload.turn);
    setHand(payload.hand);
    setPlayers({
      players: payload.players,
      id: payload.id,
    });

    startGame();
    Sounds.play('restart');
  });

  const notifyYourTurn = () => {
    Sounds.play('turn');
  };

  useSocket('PLAY_CARDS', (payload) => {
    console.log('PLAY_CARDS', payload);
    if ('cards' in payload) {
      const {
        cards,
        thisTurn,
        remainingCards,
      } = payload;
  
      addMove(cards);
      setRemainingCards({ id: thisTurn, remainingCards });
    }

    if (payload.nextTurn === playerId && playerTurn !== playerId) {
      notifyYourTurn();
    }

    setHasTheLead(payload.lead);
    setPlayerTurn(payload.nextTurn);
  });

  useSocket('PASS_TURN', (payload) => {
    console.log('PASS_TURN', payload);

    if (payload.nextTurn === playerId && playerTurn !== playerId) {
      notifyYourTurn();
    }

    setHasTheLead(payload.lead);
    setPlayerTurn(payload.nextTurn);
  });

  const player = players.find((player) => player.id === playerId);
  const waitingForPlayer = players.find((player) => player.id === playerTurn);

  const isValidMove = () => {
    const selectedCards = hand.filter((card) => card.selected);
    
    if (selectedCards.length < 1 || selectedCards.length > 4) return false;
    if (!selectedCards.every(({ rank }) => rank === selectedCards[0].rank)) return false;

    if (!hasTheLead && topCards.length > 0) {
      if (topCards.length !== selectedCards.length) return false;
      if (selectedCards[0].rank <= topCards[0].rank) return false;
    }

    return true;
  };

  const play = () => {
    if (isValidMove()) {
      playCards();

      const selectedCards = hand.filter((card) => card.selected);
      socket.emit('PLAY_CARDS', {
        cards: selectedCards,
      });

      Sounds.playRand(['card1', 'card2', 'card3']);

      if (hand.length - selectedCards.length === 0) {
        Sounds.play('victory');
      }
    } else {
      Sounds.play('invalid');
    }
  };

  const pass = () => {
    console.log('Pass turn');

    if (isCitizen() && !hasTraded) {
      doneTrading();
    }

    socket.emit('PASS_TURN');
    Sounds.play('pass');
  };

  const trade = () => {
    console.log('Trading cards');

    const selectedCards = hand.filter((card) => card.selected);
    socket.emit('TRADE_CARDS', {
      cards: selectedCards,
    });

    if (!isCitizen()) {
      doneTrading();
    }

    Sounds.play('trade');
  };

  return (
    <div className={classes.container}>
      <p className={classes.gameStatus}>
        {gamePhase === 'TRADING'
          ? (hasTraded
            ? 'Waiting for other players to trade their cards'
            : (isCitizen()
              ? 'Select 2 cards to trade or pass this turn'
              : 'Take a look at your cards and press trade'
            )
          )
          : (isTurn()
            ? 'Your turn'
            : `Waiting for ${waitingForPlayer.username}`
          )
        }
      </p>

      <AnimateSharedLayout>
        <div className={classes.gameArea}>
          <Player style={{ gridArea: 'self', alignSelf: 'end' }} inverted={true} playerIndex={0} />
          <Player style={{ gridArea: 'player1', alignSelf: 'end' }} inverted={true} playerIndex={1} />
          <Player style={{ gridArea: 'player2', alignSelf: 'start' }} playerIndex={2} />
          <Player style={{ gridArea: 'player3', alignSelf: 'start' }} playerIndex={3} />
          <Player style={{ gridArea: 'player4', alignSelf: 'end' }} inverted={true} playerIndex={4} />

          <DiscardPile />
          <TopCards />
        </div>
        <div className={classes.board}>
          <div className={classes.hand}>
            {hand.map(({ suit, rank }) => (
              <Card suit={suit} rank={rank} key={`card_${suit}_${rank}`} />
            ))}
          </div>
          <div className={classes.controls}>
            {gamePhase === 'PLAYING' || isCitizen() ? (
              <ControlButton onClick={pass} disabled={(gamePhase === 'PLAYING' && !isTurn()) || (gamePhase === 'TRADING' && hasTraded)}>
                <PassIcon />
              </ControlButton>
            ) : null}

            {gamePhase === 'TRADING' ? (
              <ControlButton onClick={trade} disabled={hasTraded}>
                <TradeIcon />
              </ControlButton>
            ) : null}
            
            {gamePhase === 'PLAYING' ? (
              <ControlButton onClick={play} disabled={!isTurn()}>
                <PlayIcon />
              </ControlButton>
            ) : null}
          </div>
        </div>
      </AnimateSharedLayout>
    </div>
  );
}

export default GameView;
