import { action } from 'easy-peasy';

const userModel = {
  id: null,
  setId: action((state, payload) => {
    state.id = payload;
  }),
};

export default userModel;
