import { action } from 'easy-peasy';
import { removeItemsFromArray, moveFirstItemToEnd } from 'common/Utils';

const gameModel = {
  started: false,
  startGame: action((state, payload) => {
    state.started = true;
  }),
  resetGame: action((state, payload) => {
    state.started = false;
    state.gamePhase = 'TRADING';
    state.hasTraded = false;
    state.players = [];
    state.hand = [];
    state.playerTurn = null;
    state.hasTheLead = false;
    state.topCards = [];
    state.discardPile = [];
  }),

  gamePhase: 'TRADING',
  setGamePhase: action((state, payload) => {
    state.gamePhase = payload;
  }),

  hasTraded: false,
  doneTrading: action((state, payload) => {
    state.hasTraded = true;
  }),

  playerId: null,
  setPlayerId: action((state, payload) => {
    state.playerId = payload;
  }),

  players: [],
  addPlayer: action((state, payload) => {
    state.players.push(payload);
  }),
  removePlayer: action((state, payload) => {
    state.players = state.players.filter(({ id }) => id !== payload.id);
  }),
  setPlayers: action((state, payload) => {
    let { players } = payload;

    const selfIndex = players.findIndex(({ id }) => id === payload.id);

    for (let i = 0; i < selfIndex; i += 1) {
      players = moveFirstItemToEnd(players);
    }

    state.players = players.map((player) => ({
      ...player,
      remainingCards: 6,
    }));
  }),

  setRemainingCards: action((state, payload) => {
    const { id, remainingCards } = payload;

    state.players = state.players.map((player) => {
      if (player.id === id) {
        return {
          ...player,
          remainingCards,
        };
      }
      return player;
    });
  }),

  hand: [],
  setHand: action((state, payload) => {
    state.hand = payload.map((card) => ({
      ...card,
      selected: false,
    }));
  }),

  toggleCardSelection: action((state, payload) => {
    state.hand = state.hand.map((card) => {
      if (card.suit === payload.suit && card.rank === payload.rank) {
        return {
          ...card,
          selected: !card.selected,
        };
      }
      return card;
    })
  }),

  playerTurn: null,
  setPlayerTurn: action((state, payload) => {
    state.playerTurn = payload;
  }),

  hasTheLead: false,
  setHasTheLead: action((state, payload) => {
    state.hasTheLead = payload;
  }),

  topCards: [],
  discardPile: [],
  playCards: action((state, payload) => {
    // Get all selected cards in our hand
    const cards = state.hand.filter((card) => card.selected);

    // Remove the selected cards from our hand
    state.hand = removeItemsFromArray(state.hand, cards);

    // Add the top cards (if any) to the discard pile
    state.discardPile = [...state.discardPile, ...state.topCards];

    // Replace the top cards with the selected cards
    state.topCards = cards;
  }),

  addMove: action((state, payload) => {
    // Add the top cards (if any) to the discard pile
    state.discardPile = [...state.discardPile, ...state.topCards];

    // Replace the top cards with the selected cards
    state.topCards = payload;
  }),
};

export default gameModel;
