import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  button: {
    fontWeight: 800,
    fontSize: '1em',
    color: ({ theme }) => theme.colorPrimary,
    backgroundColor: '#fff',
    cursor: 'pointer',
    padding: '0.4em 1.3em',
    borderRadius: '9999px',
    border: 'none',
    '&:hover': {
      color: '#fff',
      backgroundColor: ({ theme }) => theme.colorNeutral,
    },
  },
});

function AccountButton({
  label = 'Button',
  onClick = () => {},
  style = {}
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <button
      type="button"
      onClick={onClick}
      className={classes.button}
      style={{ ...style }}
    >
      {label}
    </button>
  );
}

export default AccountButton;
