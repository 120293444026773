import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import firebase from 'firebase/app';
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';

import Panel from 'components/common/Panel';
import Emoji from 'components/common/Emoji';
import Login from 'components/common/Login';
import InputGroup from 'components/common/InputGroup';

import Logo from 'assets/logo.jpg';

const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '320px',
  },
  logo: {
    borderRadius: '100%',
    width: '64px',
    height: '64px',
    boxShadow: ({ theme }) => theme.shadowPrimary,
  },
  title: {
    fontWeight: 800,
    fontSize: '2em',
    color: '#fff',
    textAlign: 'center',
  },
  links: {
    fontSize: '0.7em',
    color: '#fff',
    textAlign: 'center',
  },
  copyright: {
    fontSize: '0.7em',
    color: '#fff',
  },
  link: {
    color: ({ theme }) => theme.colorSecondary,
    textDecoration: 'none',
    '&:hover': {
      color: ({ theme }) => theme.colorNeutral,
    },
  },
});

const auth = firebase.auth();

function Menu({ history }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [user] = useAuthState(auth);

  /**
   * Navigate to route /game/:gameId
   *
   * @param {string} gameId   The id of the game to navigate to
   */
  const createGame = (gameId) => {
    history.push(`/game/${gameId}`);
  };

  return (
    <div className={classes.wrapper}>
      <img src={Logo} alt="Peasant" className={classes.logo} />
      <p className={classes.title}>
        Join a game
      </p>
      <Panel maxWidth="400px">
        <Login />

        {user ? (
          <InputGroup
            label="Join"
            placeholder="Type the game id"
            onSubmit={createGame}
          />
        ) : null}
      </Panel>
      <p className={classes.links}>
        <a href="/privacy-policy" className={classes.link} target="_blank">Privacy Policy</a> | <a href="/data-deletion" className={classes.link} target="_blank">Data Deletion</a> | <a href="/credits" className={classes.link} target="_blank">Credits</a>
      </p>
      <p className={classes.copyright}>
        Copyright &copy; 2020 <a href="https://icyhippo.com/" className={classes.link} rel="noreferrer" target="_blank">Icy Hippo</a>
      </p>
    </div>
  );
}

export default Menu;
