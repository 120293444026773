import React, { useMemo, useCallback } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { motion } from 'framer-motion';

import Sounds from 'common/Sounds';

const useStyles = createUseStyles({
  card: {
    backgroundColor: '#fff',
    borderWidth: '4px',
    borderStyle: 'solid',
    borderRadius: '20px',
    width: '64px',
    height: '64px',
    padding: '4px',
    boxShadow: ({ theme }) => theme.shadowPrimary,
    cursor: ({ isDiscarded }) => isDiscarded ? 'auto' : 'pointer',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  rank: {
    fontWeight: 800,
    fontSize: '2.6em',
    letterSpacing: '-0.1em',
    gridColumnStart: 1,
    gridColumnEnd: 2,
    alignSelf: 'end',
    justifySelf: 'center',
  },
  suit: {
    color: ({ darkColor }) => darkColor,
    fontSize: '2.4em',
    gridColumnStart: 2,
    gridColumnEnd: 3,
    alignSelf: 'start',
    justifySelf: 'center',
  },
  notSelected: {
    borderColor: ({ lightColor }) => lightColor,
  },
  selected: {
    borderColor: '#38d6b1',
    // TODO: Add a `boxShadow` to apply a glowing effect to the selected card
  },
  disabled: {
    opacity: 0.2,
  },
});

const spring = {
  type: 'string',
  stiffness: 500,
  damping: 30,
};

function Card({ suit, rank, style, isTopCard = false, isDiscarded = false }) {
  const theme = useTheme();
  const isRedSuit = suit === 'hearts' || suit === 'diamonds';
  const classes = useStyles({
    theme,
    isDiscarded,
    isTopCard,
    lightColor: isRedSuit ? theme.colorCardRedPale : theme.colorCardBlackPale,
    darkColor: isRedSuit ? theme.colorCardRed : theme.colorCardBlack,
  });

  const {
    hand,
    topCards,
    hasTraded,
    players,
    playerId,
    playerTurn,
    hasTheLead,
  } = useStoreState((state) => state.game);

  const toggleCardSelection = useStoreActions((actions) => actions.game.toggleCardSelection);

  const isSelected = useMemo(() => {
    if (isDiscarded) return false;
    const card = hand.find((c) => c.suit === suit && c.rank === rank);
    if (card) return card.selected;
  }, [hand, suit, rank, isDiscarded]);

  const rankMap = ['7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
  const suitMap = {
    spades: '♠',
    clubs: '♣',
    diamonds: '♦',
    hearts: '♥',
  };

  const rankSymbol = rankMap[rank];
  const suitSymbol = suitMap[suit];

  const toggleSelection = useCallback(() => {
    toggleCardSelection({ suit, rank });
    Sounds.play('select');
  }, [toggleCardSelection, suit, rank]);

  const getRandomRotation = useMemo(() => {
    if (!isDiscarded) return 0;
    return Math.floor(Math.random() * (10 + 10 + 1)) - 10;
  }, [isDiscarded]);

  const player = players.find(({ id }) => id === playerId);

  const isValid = () => {
    if (isDiscarded) return false;

    const selectedCards = hand.filter((card) => card.selected);

    if (!hasTraded) {
      if (player.role !== 'citizen') return false;
      return selectedCards.length < 2 || isSelected;
    }

    if (playerId !== playerTurn) return false;
    if (selectedCards.length > 0 && selectedCards[0].rank !== rank) return false;
    if (topCards.length > 0 && selectedCards.length === topCards.length && !hasTheLead && !isSelected) return false;
    if (topCards.length === 0 || hasTheLead) return true;
    if (rank <= topCards[0].rank) return false;
    if (hand.filter((card) => card.rank === rank).length < topCards.length) return false;
    return true;
  };

  return (
    <motion.div
      className={`
        ${classes.card}
        ${isSelected ? classes.selected : classes.notSelected}
        ${!isValid() && !isDiscarded ? classes.disabled : ''}
      `}
      style={{ ...style }}
      onClick={isValid() ? toggleSelection : () => {}}
      layoutId={`${suit}_${rank}`}
      initial={{ scale: 1.2, opacity: 0 }}
      animate={{
        rotate: getRandomRotation,
        scale: 1,
        opacity: !isValid() && !isDiscarded ? 0.2 : 1,
      }}
      transition={spring}
    >
      <span className={classes.rank}>
        {rankSymbol}
      </span>
      <span className={classes.suit}>
        {suitSymbol}
      </span>
    </motion.div>
  );
}

export default Card;
