import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import Panel from 'components/common/Panel';

import Logo from 'assets/logo.jpg';

const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    borderRadius: '100%',
    width: '64px',
    height: '64px',
    boxShadow: ({ theme }) => theme.shadowPrimary,
    marginTop: '30px',
  },
  title: {
    fontWeight: 800,
    fontSize: '2em',
    color: '#fff',
    textAlign: 'center',
  },
  link: {
    color: ({ theme }) => theme.colorPrimary,
    textDecoration: 'none',
    '&:hover': {
      color: ({ theme }) => theme.colorNeutral,
    },
  },
});

function Privacy() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.wrapper}>
      <img src={Logo} alt="Peasant" className={classes.logo} />
      <h1 className={classes.title}>
        Privacy Policy
      </h1>
      <Panel maxWidth="400px" style={{ alignItems: 'flex-start' }}>
        <p><strong>Effective date: December 29, 2020</strong></p>

        <p>Varsamis Konstantinos-Marinos (&quot;developer&quot;, &quot;I&quot;, &quot;me&quot;, or &quot;my&quot;) built the <strong>Peasant</strong> app (the &quot;app&quot; or &quot;service&quot;). This app is provided by Varsamis Konstantinos-Marinos and is intended for use as is.</p>
        <p>Any reference in this Privacy Policy to the &quot;user&quot;, &quot;you&quot;, or &quot;your&quot; refers to you, the user of the app and any reference to the developer, refers to me.</p>
        <p>This Privacy Policy has been compiled to provide you with clear and complete information about which personal data I gather and how you can view and control its handling.</p>
        <p>I will not use or share your information with anyone except as described in this Privacy Policy.</p>

        <h2 id="who-i-am">Who I am</h2>
        <p>I am Varsamis Konstantinos-Marinos, developer and owner of the <strong>Peasant</strong> app.</p>
        <p>Email address: <a href="mailto:info@icyhippo.com" className={classes.link}>info@icyhippo.com</a></p>

        <h2 id="what-personal-data">What personal data I collect</h2>
        <p><strong>I do not</strong> collect, store, and/or process any personal data, <strong>with the exception of <a href="https://policies.google.com/privacy" className={classes.link} rel="noreferrer" target="_blank">Firebase authentication</a> to sign you in with <a href="https://www.facebook.com/policy.php" className={classes.link} rel="noreferrer" target="_blank">Facebook</a></strong>.</p>
        <p>Only your username and profile picture (not even your email address) are transmitted to my server.</p>

        <h2 id="who-i-share-your-data-with">Who I share your data with</h2>
        <p>I don’t share your data with third-parties in a way as to reveal any of your personal information like email, name, etc. However, some data (including personally identifiable information) is transferred and/or stored with Firebase, which is a third-party service I use to authenticate you. This is done as a way for other players to recognize you in game.</p>

        <h2 id="how-long-i-retain-your-data">How long I retain your data</h2>
        <p>I do not retain any personally identifiable information on my server (excluding any log files used exclusively for debugging purposes).</p>
        <p>If you have signed in with Facebook, some data (including personally identifiable information) might be retained on Firebase.</p>
        <p>In case you want me to erase any personal data I hold about you, you can request it via an email to <a href="mailto:info@icyhippo.com" className={classes.link}>info@icyhippo.com</a> (for information regarding your rights, refer to the &quot;What rights you have over your data&quot; section).</p>

        <h2 id="what-rights-you-have-over-your-data">What rights you have over your data</h2>
        <p>If you are located in certain countries, including those that fall under the scope of the European General Data Protection Regulation (GDPR), data protection laws give you rights I respect to your personal data, subject to any exemptions provided by the law, including the rights to:</p>
        <ul>
            <li>
                <strong>Right of Access</strong>
                <p>The right to be informed of and request access to the personal data I process about you.</p>
            </li>
            <li>
                <strong>Right to Rectification</strong>
                <p>The right to request that I amend or update your personal data I process about you.</p>
            </li>
            <li>
                <strong>Right to Erasure</strong>
                <p>The right to request that I delete your personal data.</p>
            </li>
            <li>
                <strong>Right to Restrict</strong>
                <p>The right to request that I temporarily or permanently stop processing all or some of your personal data.</p>
            </li>
            <li>
                <strong>Right to Object</strong>
                <ul>
                    <li>The right to request that I temporarily or permanently stop processing all or some of your personal data.</li>
                    <li>The right to object to your personal data being processed for direct marketing purposes.</li>
                </ul>
            </li>
        </ul>
        <p>For these requests, please contact me at <a href="mailto:info@icyhippo.com" className={classes.link}>info@icyhippo.com</a></p>

        <h2 id="children-privacy">Children’s Privacy</h2>
        <p>I do not knowingly collect personally identifiable information from children under 18. If you are a parent or guardian and you are aware that your child has provided me with personal information, please contact me so that I will be able to do necessary actions.</p>

        <h2 id="links-to-other-sites">Links to other sites</h2>
        <p>My app may contain links to other sites that are not operated by me (for example, attributions to the authors of the sound files that are being used in the app). If you click on a third-party link, you will be directed to that third-party’s site. I strongly advise you to review the Privacy Policy of every site you visit.</p>
        <p>I have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

        <h2 id="changes-to-this-privacy-policy">Changes to this Privacy Policy</h2>
        <p>I may update my Privacy Policy from time to time ay my sole discretion. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page and updating the &quot;effective date&quot; at the top of this page. These changes are effective immediately after they are posted on this page.</p>

        <h2 id="how-to-contact-me">How to contact me</h2>
        <p>If you have any questions about my Privacy Policy, do not hesitate to contact me at <a href="mailto:info@icyhippo.com" className={classes.link}>info@icyhippo.com</a></p>
      </Panel>
    </div>
  );
}

export default Privacy;
