import { createStore } from 'easy-peasy';

import user from './models/user';
import game from './models/game';

const storeModel = {
  user,
  game,
};

export default createStore(storeModel);
