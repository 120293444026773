import React from 'react';
import { createUseStyles } from 'react-jss';

import firebase from 'firebase/app';
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';

import FacebookButton from 'components/common/FacebookButton';
import AccountButton from 'components/common/AccountButton';

const useStyles = createUseStyles({
  signInButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

firebase.initializeApp({
  apiKey: 'AIzaSyBe-cqwqz9nZ1EwCk6UVFdRvoI0iLsE5uE',
  authDomain: 'peasant-dd6f3.firebaseapp.com',
  projectId: 'peasant-dd6f3',
  storageBucket: 'peasant-dd6f3.appspot.com',
  messagingSenderId: '481947215150',
  appId: '1:481947215150:web:81a2224a9390c8f39286bf',
  measurementId: 'G-K05EE7N06G',
});

const auth = firebase.auth();

const SignInWithFacebook = () => {
  const signInWithFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    const result = await auth.signInWithPopup(provider);
    const token = result.credential.accessToken;

    localStorage.setItem('fbAccessToken', token);
  }

  return <FacebookButton onClick={signInWithFacebook} style={{ margin: '10px 0' }} />;
};

const SignInAnonymously = () => {
  const signInAnonymously = async () => {
    await auth.SignInAnonymously();
  };

  return (
    <AccountButton
      onClick={SignInAnonymously}
      label="Sign in anonymously"
    />
  );
};

const SignIn = () => {
  const classes = useStyles();

  return (
    <div className={classes.signInButtons}>
      <SignInWithFacebook />
      {/* <SignInAnonymously /> */}
    </div>
  );
};

const SignOut = () => {
  const classes = useStyles();

  return auth.currentUser && (
    <AccountButton
      onClick={() => auth.signOut()}
      label="Sign out"
      style={{ margin: '10px 0' }}
    />
  );
};

function Login() {
  const [user] = useAuthState(auth);

  return user ? <SignOut /> : <SignIn />;
}

export default Login;
