/**
 * Remove from the `array` all the items contained in the `toRemove` array
 *
 * @param {array} arr         The array to remove the items from
 * @param {array} toRemove    The array containing the items to remove
 * @return {array}            The array with the items removed
 */
export const removeItemsFromArray = (arr, toRemove) => {
  const toRemoveSet = new Set(toRemove);
  return arr.filter((item) => !toRemoveSet.has(item));
};

/**
 * Move the first item of the given array to the end and return the re-ordered array
 *
 * @param {array} arr         The array to re-order
 * @return {array}            The re-ordered array
 */
export const moveFirstItemToEnd = (arr) => {
  const [first, ...rest] = arr;
  return [...rest, first];
};
