import React from 'react';
import { ThemeProvider } from 'react-jss';
import { SocketIOProvider } from 'use-socketio';
import { StoreProvider } from 'easy-peasy';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import store from './store';

import Wrapper from './components/common/Wrapper';

import Menu from './routes/Menu';
import GameWrapper from './routes/GameWrapper';
import DataDeletion from './routes/DataDeletion';
import Privacy from './routes/Privacy';
import Credits from './routes/Credits';

const theme = {
  colorPrimary: '#736357',
  colorSecondary: '#b5a38b',
  colorNeutral: '#998675',
  colorDanger: 'hsl(342, 85%, 52%)',

  colorPrimaryPale: 'hsla(214, 100%, 50%, 0.7)',
  colorSecondaryPale: 'hsla(0, 0%, 60%, 0.7)',
  colorDangerPale: 'hsla(342, 85%, 52%, 0.7)',

  colorCardRed: '#ff333c',
  colorCardBlack: '#000',

  colorCardRedPale: '#ffcccc',
  colorCardBlackPale: '#e6e6e6',

  shadowPrimary: '0 4px 12px -6px rgba(0, 0, 0, 0.2)',
};

function App() {
  return (
    <StoreProvider store={store}>
      <SocketIOProvider
        url={process.env.REACT_APP_WEBSOCKET_URL}
        opts={{ transports: ['websocket'], forceNode: true }}
      >
        <ThemeProvider theme={theme}>
          <Wrapper>
            <BrowserRouter>
              <Switch>
                <Route path="/" exact component={Menu} />
                <Route path="/game/:gameId" component={GameWrapper} />
                <Route path="/data-deletion" component={DataDeletion} />
                <Route path="/privacy-policy" component={Privacy} />
                <Route path="/credits" component={Credits} />
              </Switch>
            </BrowserRouter>
          </Wrapper>
        </ThemeProvider>
      </SocketIOProvider>
    </StoreProvider>
  );
}

export default App;
