import React from 'react';
import { useStoreState } from 'easy-peasy';

import Card from './Card';

function DiscardPile() {
  const discardPile = useStoreState((state) => state.game.discardPile);

  return (
    <>
      {discardPile.map(({ suit, rank }, index) => (
        <Card
          key={`discarded_card_${suit}_${rank}`}
          suit={suit}
          rank={rank}
          style={{ gridArea: 'pile' }}
          isDiscarded={true}
        />
      ))}
    </>
  );
}

export default DiscardPile;
