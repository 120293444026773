import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  button: {
    width: '64px',
    height: '64px',
    background: 'transparent',
    padding: 0,
    border: 'none',
    cursor: 'pointer',
  },
  disabled: {
    opacity: 0.2,
  },
});

function ControlButton(props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const {
    style = {},
    onClick = () => {},
    disabled = false,
  } = props;

  return (
    <button
      className={`${classes.button} ${disabled ? classes.disabled : ''}`}
      style={{ ...style }}
      onClick={onClick}
      disabled={disabled}
    >
      {props.children}
    </button>
  );
}

export default ControlButton;
