import Card1Sound from 'assets/sounds/card1.wav';
import Card2Sound from 'assets/sounds/card2.wav';
import Card3Sound from 'assets/sounds/card3.wav';
import ConnectedSound from 'assets/sounds/connected.wav';
import DisconnectedSound from 'assets/sounds/disconnected.wav';
import InvalidSound from 'assets/sounds/invalid.wav';
import SelectSound from 'assets/sounds/select.wav';
import StartSound from 'assets/sounds/start.wav';
import TradeSound from 'assets/sounds/trade.wav';
import PassSound from 'assets/sounds/pass.wav';
import RestartSound from 'assets/sounds/restart.wav';
import VictorySound from 'assets/sounds/victory.wav';
import TurnSound from 'assets/sounds/turn.wav';

const sounds = {
  card1: Card1Sound,
  card2: Card2Sound,
  card3: Card3Sound,
  connected: ConnectedSound,
  disconnected: DisconnectedSound,
  invalid: InvalidSound,
  select: SelectSound,
  start: StartSound,
  trade: TradeSound,
  pass: PassSound,
  restart: RestartSound,
  victory: VictorySound,
  turn: TurnSound,
};

/**
 * Play the sound with the given name
 *
 * @param {string} name     The name of the sound to play
 */
const play = (name) => {
  if (!(name in sounds)) {
    return;
  }

  const audio = new Audio(sounds[name]);
  audio.play();
};

/**
 * Play one of the sounds with the given names randomly selected
 *
 * @param {string[]} names  The names of the possible sounds to play
 */
const playRand = (names) => play(names[Math.floor(Math.random() * (names.length - 1))]);

const exported = {
  play,
  playRand,
};

export default exported;
