import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '10px',
  },
});

function Wrapper({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {children}
    </div>
  );
}

export default Wrapper;
