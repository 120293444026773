import React from 'react';
import { useStoreState } from 'easy-peasy';

import Game from './Game';
import Lobby from './Lobby';

/**
 * A wrapper component to decide which component to render for this route
 *
 * @param {object} props
 */
function GameWrapper(props) {
  const gameId = props.match.params.gameId;
  const started = useStoreState((state) => state.game.started);

  return (
    <>
      {started ? <Game gameId={gameId} /> : <Lobby gameId={gameId} />}
    </>
  );
}

export default GameWrapper;
