import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreState } from 'easy-peasy';
import { motion } from 'framer-motion';

import Avatar from './Avatar';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100px',
    gap: '14px',
  },
  avatarContainer: {
    order: ({ inverted }) => inverted ? 2 : 1,
    boxShadow: ({ theme }) => theme.shadowPrimary,
    borderRadius: '100%',
    position: 'relative',
  },
  outline: {
    position: 'absolute',
    top: '-9px',
    left: '-9px',
    right: '-9px',
    bottom: '-9px',
    border: '6px solid #000',
    borderRadius: '50%',
    opacity: 0.4,
  },
  minicards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: '2px',
    rowGap: '2px',
    order: ({ inverted }) => inverted ? 1 : 2,
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  miniCard: {
    width: '24px',
    height: '24px',
    border: '2px solid #fff',
    borderRadius: '8px',
    backgroundColor: ({ theme }) => theme.colorCardBlackPale,
  },
});

const spring = {
  type: 'string',
  stiffness: 500,
  damping: 30,
};

const miniCardsContainer = {
  hidden: {
    opacity: 0,
    scale: 0,
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const miniCardVariants = {
  hidden: { opacity: 0, scale: 0 },
  show: { opacity: 1, scale: 1 },
};

function Player({ playerIndex, style, inverted = false }) {
  const theme = useTheme();
  const classes = useStyles({ theme, inverted });

  const { playerTurn, players } = useStoreState((state) => state.game);

  const player = players[playerIndex];
  const isTurn = playerTurn === player.id;
  const remainingCards = player.remainingCards;

  const renderMiniCards = () => {
    const miniCards = [];
    for (let i = 0; i < remainingCards; i += 1) {
      miniCards.push(
        <motion.li
          key={`minicard${i}`}
          className={classes.miniCard}
          variants={miniCardVariants}
        />
      );
    }
    return miniCards;
  };

  const roleColorMap = {
    king: '#00e7ff',
    prince: '#35d9ab',
    citizen: '#fbb03b',
    middleclass: '#ccc',
    peasant: '#c17a7c',
  };

  const playerColor = roleColorMap[player.role];

  return (
    <div className={classes.container} style={{ ...style }}>
      <div className={classes.avatarContainer} title={player.username}>
        <Avatar photoURL={player.avatar} color={playerColor} />
        {isTurn && (
          <motion.div
            layoutId="outline"
            className={classes.outline}
            initial={false}
            animate={{ borderColor: playerColor }}
            transition={spring}
          />
        )}
      </div>
      {remainingCards > 0 && playerIndex !== 0 ? (
        <motion.ul
          className={classes.minicards}
          variants={miniCardsContainer}
          initial="hidden"
          animate="show"
        >
          {renderMiniCards()}
        </motion.ul>
      ) : null}
    </div>
  );
}

export default Player;
