import React, { useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreActions } from 'easy-peasy';
import { useSocket } from 'use-socketio';

import GameView from 'components/Game/GameView';

import Sounds from 'common/Sounds';

const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function Game({ gameId }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const resetGame = useStoreActions((actions) => actions.game.resetGame);

  useEffect(() => {
    Sounds.play('start');
  }, []);

  useSocket('CLIENT_LEFT', (payload) => {
    console.log('Client disconnected', payload);
    resetGame();
    Sounds.play('disconnected');
  });

  return (
    <div className={classes.wrapper}>
      <GameView />
    </div>
  );
}

export default Game;
