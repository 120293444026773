import React, { useState, useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useSocket } from 'use-socketio';

import firebase from 'firebase/app';
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';

import Avatar from 'components/Game/Avatar';
import Sounds from 'common/Sounds';

import Logo from 'assets/logo.jpg';

const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    borderRadius: '100%',
    width: '64px',
    height: '64px',
    boxShadow: ({ theme }) => theme.shadowPrimary,
    marginTop: '30px',
  },
  status: {
    fontWeight: 800,
    fontSize: '2em',
    color: '#fff',
    textAlign: 'center',
    maxWidth: '340px',
  },
  gameId: {
    fontWeight: 800,
    fontSize: '1em',
    color: '#fff',
    backgroundColor: ({ theme }) => theme.colorSecondary,
    borderRadius: '9999px',
    padding: '14px 42px',
    maxWidth: '90%',
  },
  container: {
    backgroundColor: ({ theme }) => theme.colorSecondary,
    borderRadius: '60px',
    padding: '10px',
    width: '90%',
    maxWidth: '460px',
    minHeight: '600px',
    display: 'flex',
    fontSize: '1.6em',
  },
  players: {
    width: 'calc(100% - 2.8em)',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.4em',
  },
  player: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  avatarWrapper: {
    width: '108px',
  },
  username: {
    fontWeight: 800,
    color: '#fff',
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '10px',
  },
  numberOfPlayers: {
    backgroundColor: ({ theme }) => theme.colorNeutral,
    alignSelf: 'flex-end',
    borderRadius: '100%',
    width: '2.6em',
    height: '2.6em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '0.2em',
    marginBottom: '0.2em',
  },
  numberOfPlayersText: {
    fontWeight: 800,
    color: ({ theme }) => theme.colorSecondary,
    margin: 0,
  },
});

const auth = firebase.auth();

function Lobby({ gameId }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [isLobbyFull, setLobbyFull] = useState(false);

  const players = useStoreState((state) => state.game.players);

  const {
    addPlayer,
    removePlayer,
    setPlayers,
    setHand,
    startGame,
    setPlayerId,
    setPlayerTurn,
  } = useStoreActions((actions) => actions.game);

  const [user] = useAuthState(auth);

  const { socket } = useSocket();

  useEffect(() => {
    // Store self
    addPlayer({
      id: socket.id,
      avatar: user ? user.photoURL : null,
      username: user ? user.displayName : 'Anonymous User',
      self: true,
    });

    // Join room
    socket.emit('JOIN_GAME', {
      gameId,
      avatar: user ? user.photoURL : null,
      username: user ? user.displayName : 'Anonymous User',
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSocket('LOBBY_FULL', (payload) => {
    console.log('Lobby is full');
    setLobbyFull(true);
  });

  useSocket('JOINED_GAME', (payload) => {
    const { clients, id } = payload;

    console.log('Clients in game', clients);

    setPlayerId(id);
    clients.forEach((player) => addPlayer(player));
  });

  useSocket('CLIENT_JOINED', (payload) => {
    console.log('Client connected', payload);
    addPlayer(payload);
    Sounds.play('connected');
  });

  useSocket('CLIENT_LEFT', (payload) => {
    console.log('Client disconnected', payload);
    removePlayer(payload);
    Sounds.play('disconnected');
  });

  useSocket('START_GAME', (payload) => {
    console.log('Starting game', payload);

    setPlayerId(payload.id);
    setPlayerTurn(payload.turn);
    setHand(payload.hand);
    setPlayers({
      players: payload.players,
      id: payload.id,
    });
    startGame();
  });

  return (
    <div className={classes.wrapper}>
      <img src={Logo} alt="Peasant" className={classes.logo} />
      <p className={classes.status}>
        {isLobbyFull ? 'Sorry, lobby is full' : 'Waiting for players to join'}
      </p>
      <p className={classes.gameId}>{gameId}</p>
      {!isLobbyFull ? (
        <div className={classes.container}>
          <div className={classes.players}>
            {players.map((player) => (
              <div className={classes.player} key={`player_${player.id}`}>
                <div className={classes.avatarWrapper}>
                  <Avatar photoURL={player.avatar} />
                </div>
                <p className={classes.username}>
                  {player.username}
                </p>
              </div>
            ))}
          </div>
          <div className={classes.numberOfPlayers}>
            <p className={classes.numberOfPlayersText}>
              {`${players.length}/5`}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Lobby;
